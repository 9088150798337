/***
 * FUNCTIONS
 ***/
// Check mobile
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};



// ScrollAnim
function scrollAnim (target) {
    $(target).animatescroll({
        scrollSpeed: 800,
        easing: 'easeOutExpo',
        padding: 115,
    });
}



/***
 * GERAL
 ***/
$(function() {
    // imagesLoaded
    $('body').imagesLoaded().always( function( instance ) {
        $('body').addClass('loaded');
    });

    // menu
    var $win = $(window),
        $menu = $('.navbar'),
        $menuToggle = $('.header-top .menuToggle');

    $($menuToggle).click(function(event) {
        event.preventDefault();
        $menu.slideToggle();
        $('html').toggleClass('open-menu');
    });

    $win.resize(function(event) {
        $('html').removeClass('open-menu');

        if ($win.width() <= 768) {
            $($menu).css('display', 'none');
        } else {
            $($menu).css('display', 'block');
        }
    });

    // Fixed Menu
    $(window).scroll(function () {
        if ($(window).scrollTop() > 140) {
            $('header .navbar').addClass('fixed');
            $('header .header-top').css('margin-bottom', '50px');
        } else {
            $('header .navbar').removeClass('fixed');
            $('header .header-top').css('margin-bottom', '0');
        }
    });

    // wow
    new WOW({
        offset: 300
    }).init();

    // owl carousel
    $('.banner .owl-carousel').owlCarousel({
        items           : 1,
        loop            : true,
        autoplay        : true,
        autoplayTimeout : 3000,
        dots            : true,
        nav             : false,
        animateOut      : 'fadeOut',
    });

    $('.depoimentos-home .owl-carousel').owlCarousel({
        center          : true,
        loop            : true,
        dots            : true,
        navText         : ['<i class="icon-left"></i>','<i class="icon-right"></i>'],
        responsive:{
            768:{
                autoplay  : false,
                nav       : true,
                items     : 2,
            },
            0:{
                autoplay  : true,
                nav       : false,
                items     : 1,
            }
        }
    });


    $('.depoimentos-home .owl-carousel').append('<div class="ilust dark wow fadeInLeft"></div><div class="ilust light wow fadeInRight"></div>');

    // Galeria
    $('.galeria').each(function() {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
              enabled:true
            }
        });
    });



    // Scrollspy
    $('body').scrollspy({
        target: 'header nav',
        offset: 300
    });

    // Scroll
    $('.scroll').on('click', function(event) {
        var data   = $(this).data('target');
        var href   = $(this).attr('href');
        var target = (data !== undefined) ? data : href;

        scrollAnim(target);
    });

    // Scroll up
    $('footer .up').on('click', function(event) {
        event.preventDefault();

        $('body').animatescroll({
            scrollSpeed:1000,
            easing:'easeOutExpo',
            padding: 0
        });
    });

    // Input masks
    $("input.cpf").mask("999.999.999-99");
    $("input.cnpj").mask("99.999.999/9999-99");
    $("input.data").mask("99/99/9999");
    $("input.cep").mask("99999-999");
    $("input.hora").mask("99:99");
    $("input.telefone").focusout(function(){
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if(phone.length > 10) {
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    }).trigger('focusout');

    $('.more .p').each(function() {
        var h = $(this).height();
        console.log(h);
        if (h > 80) {
            $(this).parent().parent().addClass('show');
        }

    });

    $('.link-more').click(function(e){
        e.preventDefault();
        $(this).closest('.texto').find('.more').toggleClass('more open');
        $(this).toggleClass('hide');
    });



});

function initMapa() {
    // Mapa
    if ( $('.mapa').length ) {
        // Gmap3
        $('.mapa')
          .gmap3({
            center:[-20.8914822, -47.583552],
            zoom: 16,
            navigationControl: false,
            scrollwheel: false,
            streetViewControl: false,
            mapTypeControl: false,
          })
          .marker([
            {
                position:[-20.893086, -47.583552],
                icon: "../wp-content/themes/zanon/assets/images/pin.png"
            },
          ]);

    }
}